import '../../../vendor/matilda_core/scripts/main'
import reqwest from 'reqwest'
import 'chart.js'

export function each(array, callback, scope) {
  for (let i = 0; i < array.length; i += 1) {
    callback.call(scope, i, array[i])
  }
}
function sum(a, b) {
  return a + b;
}
export function $1(selector, context) {
  return (context || document).querySelector(selector)
}

export function $(selector, context) {
  return (context || document).querySelectorAll(selector)
}

class Application {
  constructor() {
    this.init()
  }

  init() {
    window.addEventListener('load', () => {
      console.log("loaded")
    })
  }
}

new Application()
