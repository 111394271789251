import '../../../vendor/matilda_core/loader_assets.js'

import '../style/main.scss'
// import '../scripts/TemplateData/UnityProgress.js'
// import '../scripts/Build/UnityLoader.js'
import '../scripts/main.js'

import * as Selectr from 'mobius1-selectr'

document.addEventListener("DOMContentLoaded", function(event) {
  var field = document.querySelectorAll('#matilda_core_user_uuid_selectize');
  if( field.length > 0){
    let hidden_user_uuid = document.getElementById("hidden_user_uuid").value
    console.log('hidden_user_uuid',hidden_user_uuid)
    if(hidden_user_uuid){
      new Selectr('#matilda_core_user_uuid_selectize', {
        searchable: true,
        width: 300,
        selectedValue: hidden_user_uuid
      });
    } else {
      new Selectr('#matilda_core_user_uuid_selectize', {
        searchable: true,
        width: 300
      });
    }
  }


  var field = document.querySelectorAll('#matilda_core_multiple_user_uuid_selectize');
  let hidden_user_uuids = document.getElementById("hidden_user_uuids").value.split(",")
  console.log('hidden_user_uuids',hidden_user_uuids)
  if( field.length > 0){
    new Selectr('#matilda_core_multiple_user_uuid_selectize', {
      searchable: true,
      multiple: true,
      width: 300,
      selectedValue: hidden_user_uuids
    });
  }
  // selectedValue: ["fe1e6eea-0a2e-4be4-abb8-b014f5dd03da", "ed23ea7a-5ee1-47f8-bc3e-3b1cfb3bebca", "5a384709-8558-4390-a63c-b7707f98564f"]
  // multiple: true
});